<template>
  <div>
    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ $t("route") + " " + $t("admin") }}</v-card-title>
      <v-snackbar v-model="snackbar" :color="snackbar_class" elevation="24">
        {{ text_snackbar }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="600px">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      v-model="selected.name"
                      v-bind:label="$t('brands.name')"
                      required
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.zoneId"
                      v-bind:label="$t('zone')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="name"
                      item-value="id"
                      :search-input.sync="search_zone"
                      :loading="isZoneSearching"
                      :items="filtered_zone"
                      hide-no-data
                      hide-selected
                      filled
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-autocomplete
                      v-model="selected.geographyId"
                      v-bind:label="$t('geography')"
                      v-bind:placeholder="$t('start_typing_to_search')"
                      item-text="label"
                      item-value="id"
                      :search-input.sync="search_geography"
                      :loading="isGeographySearching"
                      :items="filtered_geography"
                      hide-no-data
                      hide-selected
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.spvId"
                      label="SPV"
                      :items="spv_list"
                      item-text="username"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                      @change="changeSPV"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-autocomplete
                      v-model="selected.gpvIds"
                      label="GPV"
                      :items="gpv_list"
                      item-text="username"
                      item-value="id"
                      filled
                      multiple
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="8">
                    <v-autocomplete
                      v-model="selected.domainId"
                      label="Nombre de dominio"
                      :items="domainNamesList"
                      item-text="name"
                      item-value="id"
                      filled
                      required
                      :rules="requiredRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="d-flex justify-center"
                  >
                    <v-radio-group
                      v-model="selected.status"
                      row
                      required
                      :rules="requiredRules"
                    >
                      <v-radio label="Active" value="active"></v-radio>
                      <v-radio label="Inactive" value="inactive"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="onSaveClick(selected)">
                {{ $t("save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn color="primary" dark class="mb-2" @click="onItemEdit">
              {{ $t("newitem") }}
            </v-btn>
            <v-btn
              v-if="importExcelTemplatePathName"
              color="primary"
              @click="downloadExcelTemplateGlobal(importExcelTemplatePathName)"
              class="mb-2 ml-2"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              Plantilla Import
            </v-btn>
            <v-btn
              color="success"
              dark
              class="mb-2 ml-2"
              :loading="isSelecting"
              @click="onUploadExcelClick"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              {{ buttonText }}
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept=".xls,.xlsx"
              @change="onFileChanged"
            />
            <v-btn
              color="warning"
              dark
              class="mb-2 ml-2"
              @click="downloadExcel"
            >
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export Excel
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefroutes"
              id="routes_table"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="routes"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totalroutes"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";

export default {
  name: "routeAdminPage",
  data: function() {
    return {
      importExcelTemplatePathName: null,
      filterable_col_list: [
        "name",
        "address",
        "postalCode",
        "domainId",
        "town",
        "phone",
        "phone2",
        "contact",
        "comments",
        "status",
        "chainId",
        "subChainId",
        "channelId",
        "subChannelId",
        "country_name",
        "state_name",
        "province_name",
        "latitude",
        "longitude",
        "zoneId"
      ],
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      filterCriteria: {
        status: {},
        domainId: {}
      },
      showEmpty: false,

      totalroutes: 0,
      routes: [],
      domainNamesList: [],

      valid: true,
      requiredRules: [v => !!v || "Required"],
      selected: null,
      editedIndex: -1,
      deleteItemId: -1,
      showEdit: false,
      dialogDelete: false,

      search_geography: null,
      isGeographySearching: false,
      filtered_geography: [],

      search_zone: null,
      isZoneSearching: false,
      filtered_zone: [],

      spv_list: [],
      gpv_list: [],

      /********** others **********/
      isSelecting: false,
      selectedFile: null,
      defaultButtonText: "Import Excel",
      /********** snackbar **********/
      snackbar: false,
      text_snackbar: "The imported file is invalid.",
      vertical: true,
      snackbar_class: "deep-purple"
    };
  },
  watch: {
    async search_geography(val) {
      this.search_geography_list(val);
    },
    async search_zone(val) {
      this.search_zone_list(val);
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 || this.editedIndex === undefined
        ? this.$t("newitem")
        : this.$t("edititem");
    },
    columns() {
      return [
        {
          key: "id",
          title: "ID",
          field: "id",
          align: "left",
          width: 70,
          sortBy: "ASC",
          fixed: "left"
        },
        {
          key: "name",
          title: this.$t("salesforce.name"),
          field: "name",
          align: "left",
          sortBy: "",
          fixed: "left",
          width: 200,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["name"]}
                    on-input={value => (this.filterCriteria["name"] = value)}
                    placeholder="Search name"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "domainId",
          title: "Nombre de dominio",
          field: "domainId",
          align: "left",
          width: 200,
          sortBy: "",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            let domainName = this.getDomainNameById(row.domainId);
            return <span>{domainName}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <filterableAjaxCheckboxes
                    v-model={this.filterCriteria["domainId"]}
                    apiUrl={`brands/getfilterlist`}
                    columnName={`domainId`}
                    isFullText={true}
                    filterCriteria={this.filterCriteria["domainId"]}
                  />
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "domainId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "SPV",
          title: "SPV",
          field: "SPV",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return row.spv.map(spv => {
              return (
                <div>
                  <span>{spv.name}</span>
                  <br />
                </div>
              );
            });
          }
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["country_name"]}
          //           on-input={(value) =>
          //             (this.filterCriteria["country_name"] = value)
          //           }
          //           placeholder="Search country"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() =>
          //               this.searchCancel(closeFn, "country_name")
          //             }
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   },
          // },
        },
        {
          key: "GPV",
          title: "Main GPV",
          field: "GPV",
          align: "left",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return row.gpvs.map(gpv => {
              return (
                <div>
                  <span>{gpv.name}</span>
                  <br />
                </div>
              );
            });
          }
          // filterCustom: {
          //   defaultVisible: false,
          //   render: ({ showFn, closeFn }, h) => {
          //     return (
          //       <div class="custom-filter">
          //         <v-text-field
          //           value={this.filterCriteria["country_name"]}
          //           on-input={(value) =>
          //             (this.filterCriteria["country_name"] = value)
          //           }
          //           placeholder="Search country"
          //         ></v-text-field>
          //         <div class="custom-filter-operation">
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() =>
          //               this.searchCancel(closeFn, "country_name")
          //             }
          //           >
          //             {this.$t("cancel")}
          //           </v-btn>
          //           <v-btn
          //             color="blue darken-1"
          //             text
          //             on-click={() => this.searchConfirm(closeFn)}
          //           >
          //             {this.$t("Search")}
          //           </v-btn>
          //         </div>
          //       </div>
          //     );
          //   },
          // },
        },
        {
          key: "country_name",
          title: this.$t("country"),
          field: "country_name",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["country_name"]}
                    on-input={value =>
                      (this.filterCriteria["country_name"] = value)
                    }
                    placeholder="Search country"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "country_name")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "state_name",
          title: this.$t("state"),
          field: "state_name",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["state_name"]}
                    on-input={value =>
                      (this.filterCriteria["state_name"] = value)
                    }
                    placeholder="Search state"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "state_name")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "province_name",
          title: this.$t("province"),
          field: "province_name",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["province_name"]}
                    on-input={value =>
                      (this.filterCriteria["province_name"] = value)
                    }
                    placeholder="Search province"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() =>
                        this.searchCancel(closeFn, "province_name")
                      }
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "zoneId",
          title: this.$t("zone"),
          field: "zoneId",
          align: "left",
          sortBy: "",
          width: 150,
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return <span>{row.zone !== null ? row.zone.name : ""}</span>;
          },
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-text-field
                    value={this.filterCriteria["zoneId"]}
                    on-input={value => (this.filterCriteria["zoneId"] = value)}
                    placeholder="Search zone"
                  ></v-text-field>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "zoneId")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "status",
          title: this.$t("status"),
          field: "status",
          align: "left",
          sortBy: "",
          width: 150,
          filterCustom: {
            defaultVisible: false,
            render: ({ showFn, closeFn }, h) => {
              return (
                <div class="custom-filter">
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["active"]}
                    label="Active"
                    value="active"
                  ></v-checkbox>
                  <v-checkbox
                    v-model={this.filterCriteria["status"]["inactive"]}
                    label="InActive"
                    value="inactive"
                  ></v-checkbox>
                  <div class="custom-filter-operation">
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchCancel(closeFn, "status")}
                    >
                      {this.$t("cancel")}
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      on-click={() => this.searchConfirm(closeFn)}
                    >
                      {this.$t("Search")}
                    </v-btn>
                  </div>
                </div>
              );
            }
          }
        },
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-icon
                  medium
                  color="primary"
                  class="mr-2"
                  on-click={() => this.onItemEdit(row)}
                >
                  mdi-pencil
                </v-icon>
              </div>
            );
          }
        }
      ];
    },
    buttonText() {
      return this.isSelecting ? "Uploading..." : this.defaultButtonText;
    }
  },
  methods: {
    getDomainNameById(domainId) {
      for (let el of this.domainNamesList) {
        if (el.id === domainId) return el.name;
      }
      return null;
    },
    async search_geography_list(val) {
      let filterable = this.filtered_geography.filter(
        item => item.label === val
      );
      if (val !== null && filterable.length === 0) {
        this.isGeographySearching = true;
        let fetch_url = "pos/get_geographies?filter_name=" + val;
        const resp = await ApiService.get(fetch_url);
        this.filtered_geography = resp.data;
        this.isGeographySearching = false;
      }
    },
    async search_zone_list(val) {
      let filterable = this.filtered_zone.filter(item => item.label === val);
      if (val !== null && filterable.length === 0) {
        let fetch_url = "pos/get_zones?filter_name=" + val;
        this.isZoneSearching = true;
        const resp = await ApiService.get(fetch_url);
        this.filtered_zone = resp.data;
        this.isZoneSearching = false;
      }
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "status" || cancelFilterKey === "domainId") {
        this.filterCriteria[cancelFilterKey] = {};
      } else if (
        cancelFilterKey === "latitude" ||
        cancelFilterKey === "longitude"
      ) {
        this.filterCriteria[cancelFilterKey]["from"] = "";
        this.filterCriteria[cancelFilterKey]["to"] = "";
      } else {
        this.filterCriteria[cancelFilterKey] = null;
      }
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#routes_table"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    async changeSPV(spv_id) {
      let fetch_url = `pos/routes/get_gpvs?spv_id=${spv_id}`;
      const resp = await ApiService.get(fetch_url);
      this.gpv_list = resp.data;
      this.selected.gpvs = [];
      this.selected.gpvIds = [];
    },
    async createSelectedModel(item) {
      let model = {
        id: _.get(item, "id"),
        name: _.get(item, "name"),
        domainId: _.get(item, "domainId"),
        geographyId: _.get(item, "geographyId"),
        geography: _.get(item, "geography"),
        zoneId: _.get(item, "zoneId"),
        zone: _.get(item, "zone"),
        status: _.get(item, "status"),
        spv: _.get(item, "spv"),
        gpvs: _.get(item, "gpvs")
      };
      this.gpv_list = [];
      if (!model.status) {
        model.status = "active";
      }
      if (model.spv && model.spv.length > 0) {
        model.spvId = model.spv[0].id;
      }
      if (!model.id) {
        await this.search_zone_list("");
        await this.search_geography_list("");
        model.gpvIds = [];
      } else {
        if (model.geographyId && model.geography) {
          let selected_geography = {
            id: model.geographyId,
            label:
              _.get(item, "province_name") +
              ", " +
              _.get(item, "state_name") +
              ", " +
              _.get(item, "country_name")
          };
          this.filtered_geography = [selected_geography];
        }
        if (model.zoneId && model.zone) {
          let selected_zone = {
            id: model.zoneId,
            name: _.get(item, "zone").name
          };
          this.filtered_zone = [selected_zone];
        }
        if (model.gpvs && model.gpvs.length > 0) {
          model.gpvIds = model.gpvs.map(item => {
            return item.id;
          });
          this.gpv_list = model.gpvs;
        }
      }
      console.log(model);
      return model;
    },
    async onItemEdit(item) {
      this.selected = await this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.filtered_geography = [];
        this.filtered_zone = [];
        this.gpv_list = [];
        this.editedIndex = -1;
      });
    },
    async onSaveClick(item) {
      console.log(item);
      if (this.$refs.form.validate()) {
        let body = item;
        if (body.id) {
          await ApiService.put(`pos/routes/${body.id}`, body);
        } else {
          await ApiService.post(`pos/routes/`, body);
        }
        logInfo("Ruta actualizada");
        this.showEdit = false;
        this.getDataFromApi();
      }
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#routes_table .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "pos/routes";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.routes = resp.data;
        this.spv_list = resp.spv_list;
        this.domainNamesList = resp.domainNamesList;
        this.importExcelTemplatePathName = resp.importExcelTemplatePathName;

        if (this.routes.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totalroutes = resp.total;
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      console.log(query_params_string);
      let fetch_url = "pos/routes/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const response = await ApiService.get(fetch_url, {
          responseType: "blob"
        });
        download(response, "routes.xlsx");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    onUploadExcelClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: false }
      );
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      console.log(this.selectedFile);
      if (window.confirm(`Are you sure you want to upload this excel-file?`)) {
        this.isSelecting = true;
        let data = new FormData();
        data.append("file", this.selectedFile);
        this.$refs.uploader.value = "";
        try {
          const response = await ApiService.post(
            `pos/routes/uploadexcel`,
            data,
            {
              headers: {
                "Content-Type": `multipart/form-data;`
              }
            }
          );
          const {
            success,
            insertedRowCount,
            failedRows,
            invalidFile,
            message
          } = response;
          this.snackbar_class = "deep-purple";
          if (success && insertedRowCount > 0) {
            this.text_snackbar = `${insertedRowCount} row${
              insertedRowCount > 1 ? "s" : ""
            } imported successfully`;
            this.snackbar = true;
          } else if (failedRows) {
            this.text_snackbar = `Inserted count: ${response.insertedRowCount}, Failed count: ${response.failedRows.length}`;
            this.snackbar = true;
            this.exportCSVfileGlobal(response.titles, response.failedRows);
          } else if (!invalidFile && message) {
            this.text_snackbar = message;
            this.snackbar_class = "red";
            this.snackbar = true;
          }
          this.isSelecting = false;
          this.getDataFromApi();
        } catch (error) {
          console.log(error);
        }
      }
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style></style>
